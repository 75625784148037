<template>
  <b-nav-item-dropdown
    ref="dropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="unViewed"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>
    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Értesítések
        </h4>
        <b-badge
          pill
          variant="light-danger mr-1"
          style="cursor: pointer;"
          @click="deleteNotifications()"
        >
          Értesítések törlése
        </b-badge>
        <b-badge
          pill
          variant="light-primary"
        >
          {{unViewed}} új
        </b-badge>
      </div>
    </li>

    <!-- Account Notification -->
    <div style="max-height: 400px;overflow: auto;">
      <div
        v-for="(notification, index) in notifications"
        :key="index"
      >
        <b-media style="cursor:pointer" class="pl-2 pr-2 pt-1 pb-1" @click="goToOtherPage(notification.data, notification.id)" :class="{ 'seen-notification': notification.viewed == 1 }">
          <p class="media-heading" style="padding-bottom:3px">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text" style="line-height:30%" v-html="notification.message"></small>
        </b-media>
      </div>
    </div>

    <!-- Cart Footer -->
    <!--<li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Összes értesítés megtekintése</b-button>
    </li>-->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import NotificationSound from '@/assets/sound/notification.wav'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
  },
  mounted() {
    this.getNotifications()
    this.getCountUnviewedNotifications()

    setInterval(() => {
      this.getNotifications()
      this.getCountUnviewedNotifications()
    }, 1000 * 60 * 5)
  },
  data() {
    return {
      notifications: [],
      unViewed: 0,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  watch: {
    unViewed(val) {
      if (val > 0) {
        const audio = new Audio(NotificationSound)
        audio.play()
      }
    },
  },
  methods: {
    goToOtherPage(data, id) {
      if (data.params) {
        this.$router.push({ name: data.page, params: data.params })
        this.setViewed(id)
      } else {
        this.$router.push({ name: data.page })
        this.setViewed(id)
        this.$refs.dropdown.hide()
      }
    },
    getCountUnviewedNotifications() {
      axios({
        method: 'get',
        url: 'notification/unViewed',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.unViewed = response.data
      })
    },
    refreshNotifications() {
      this.getNotifications()
      this.getCountUnviewedNotifications()
    },
    setViewed(id) {
      axios({
        method: 'get',
        url: `notification/setViewed/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        setTimeout(() => {
          window.location.reload()
        }, 300)
      })
    },
    getNotifications() {
      axios({
        method: 'get',
        url: 'notification/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.notifications = res.data
      })
    },
    deleteNotifications() {
      axios({
        method: 'delete',
        url: 'notification/delete',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.notifications = null
      })
    },
    deleteNotificationsSingle(id) {
      axios({
        method: 'delete',
        url: `notification/delete/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getNotifications()
      })
    },
  },
  directives: {
    Ripple,
  },
}
</script>
<style lang="css">
  .notification-line{
    margin-bottom: 1px !important;
    line-height: 1.0rem !important;
  }
  .seen-notification{
    opacity: 0.8;
    background: rgba(62,116,199, 0.2);
  }

  .seen-notification .notification-text{
    color:#6e6b7b !important
  }
</style>
