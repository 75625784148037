export default [
  {
    title: 'Kezdőlap',
    icon: 'HomeIcon',
    tagVariant: 'light-success',
    route: 'monolit.dashboard',
    roles: 'dashboard',
  },
  {
    title: 'Feladatok',
    icon: 'BriefcaseIcon',
    tagVariant: 'light-success',
    route: 'monolit.global.todo',
    roles: 'todo',
  },
  {
    title: 'Szabadságok',
    icon: 'StarIcon',
    tagVariant: 'light-success',
    route: 'monolit.freedays.list',
    roles: 'freeday_menu',
  },
  {
    title: 'Jelenléti ívek',
    icon: 'DollarSignIcon',
    tagVariant: 'light-success',
    route: 'monolit.accounting.year',
    roles: 'accounting',
  },
  {
    title: 'Alkalmazottak',
    icon: 'UsersIcon',
    tagVariant: 'light-success',
    route: 'monolit.employee.list',
    roles: 'employee_menu',
  },
  {
    title: 'Járművek',
    icon: 'TruckIcon',
    tagVariant: 'light-success',
    route: 'monolit.car.list',
    roles: 'car_menu',
  },
  {
    title: 'Hitelezés',
    icon: 'BookIcon',
    tagVariant: 'light-success',
    route: 'monolit.credit.list',
    roles: 'credit_menu',
  },
  {
    title: 'Levonások',
    icon: 'DollarSignIcon',
    tagVariant: 'light-success',
    roles: 'private_comment',
    route: 'monolit.private_comment.list',
  },
  {
    title: 'Számlák',
    icon: 'FileTextIcon',
    tagVariant: 'light-success',
    roles: 'invoice_progress',
    route: 'monolit.invoice.list',
  },
  {
    title: 'Alvállakozók',
    icon: 'UsersIcon',
    tagVariant: 'light-success',
    roles: 'subcontractor',
    route: 'monolit.subcontractor.list',
  },
  {
    title: 'Projektek',
    icon: 'BriefcaseIcon',
    tagVariant: 'light-success',
    roles: 'project',
    route: 'monolit.projects.list',
  },
  {
    title: 'Ajánlati lista',
    icon: 'BriefcaseIcon',
    tagVariant: 'light-success',
    roles: 'project',
    route: 'monolit.project.total.offers',
  },
  {
    title: 'Riportok',
    icon: 'BarChartIcon',
    tagVariant: 'light-success',
    roles: 'report',
    route: 'monolit.report.list',
  },
  {
    title: 'Rendelések',
    icon: 'BoxIcon',
    tagVariant: 'light-info',
    roles: 'order_menu',
    children: [
      {
        title: 'Rendeléseim',
        route: 'monolit.order.own',
        roles: 'create_order',

      },
      {
        title: 'Létrehozás',
        route: 'monolit.order.create',
        roles: 'create_order',
      },
      {
        title: 'Rendelések',
        route: 'monolit.order.list.list',
        roles: 'progress_order',
      },
      {
        title: 'Számlák',
        route: 'monolit.order.invoice.list',
        roles: 'invoices_offer',
      },
    ],
  },
  {
    title: 'Felhasználók',
    icon: 'UserIcon',
    tagVariant: 'light-info',
    roles: 'user',
    route: 'monolit.user.list',
  },
  {
    title: 'Viber beállítások',
    icon: 'SendIcon',
    tagVariant: 'light-success',
    roles: 'viber',
    route: 'monolit.viber.settings',
  },
  {
    title: 'Extra mezők',
    icon: 'SettingsIcon',
    tagVariant: 'light-success',
    route: 'monolit.extrafields.list',
    roles: 'extra_fields',
  },
]
