<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex d-lg-flex">
      <dark-Toggler class="d-block d-lg-block" />
    </div>

    <div class="d-none d-md-block d-lg-block mr-2">
      <b-avatar
        v-for="(user, index) in activeUsers"
        :key="index"
        size="40"
        variant="light-primary"
        badge
        class="badge-minimal ml-1"
        badge-variant="success"
        :text="avatarText(user.name)"
        :title="user.name"
        :src="'https://monolitcrm.hu/images/'+user.id+'.jpg'"
      />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{name}}
            </p>
            <!--<span class="user-status"></span>-->
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
            :text="avatarText(name)"
            :src="'https://monolitcrm.hu/images/'+id+'.jpg'"
          />
        </template>

        <b-dropdown-item @click="logout" link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Kijelentkezés</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import axios from 'axios'
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import NotificationDropdown from './NotificationDropdown.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    NotificationDropdown,

    // Navbar Components
    DarkToggler,
  },
  data() {
    return {
      avatarText,
      name: null,
      role: null,
      id: null,
      activeUsers: null,
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    this.name = JSON.parse(localStorage.getItem('user')).name
    this.role = JSON.parse(localStorage.getItem('user')).role
    this.id = JSON.parse(localStorage.getItem('user')).id
    this.listActiveUsers()
    setInterval(() => {
      this.listActiveUsers()
    }, 1000 * 60 * 10)
  },
  methods: {
    listActiveUsers() {
      axios({
        method: 'get',
        
        url: 'user/list/active',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.activeUsers = null
        this.activeUsers = res.data
      })
    },
    logout() {
      localStorage.removeItem('user')
      this.$router.push({ name: 'monolit.login' })
    },
  },
}
</script>
